.App {
  text-align: left;
  background-color: #c2cad0;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #c2cad0;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body-main {
  background-color: white;
  max-width: 1300px;
  min-height: auto;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}
  

@media only screen and (max-width: 900px) {
    .body-main {
        padding-left: 15px;
        padding-right: 15px;
    }
 
    .navigation {
        position: relative;
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        padding: 0.5rem 0rem;
        background-color: #fff;
        color: black;
    }
  
    .navigation-menu {
        margin-left: auto;
    }
    
    .navigation-menu ul {
        padding: 0;
        flex-direction: column;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: calc(100vh - 80px);
        background-color: white;
        /* border-top: 1px solid black; */
        display: none;
    }
    
    .navigation-menu li {
        list-style-type: none;
        margin: 0 0rem;
    }
    
    .navigation-menu li a {
        text-decoration: none;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 0;
        color: black;
        padding: 0.6rem 0;
    }

    .navigation-menu li:hover {
        background-color: #eee;
    }
    
    .hamburger {
        border: 0;
        height: 50px;
        width: 50px;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: white;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        display: block;
    }
    
    .hamburger:hover {
        background-color: #eee;
    }

    .navigation-menu.expanded ul {
        top: 80px;
        display: block;
    }
    
}

.full-img {
  max-width: 100%;
  margin: auto;
}

.header {
  width: 100%;
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  justify-content: space-between;
}

.nav {
  width: 100%;
  margin-left: 3em;
  margin-right: 3em;
  display: flex;
  justify-content: space-between;
}

/* Style the links inside the navigation bar */
.nav a {
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

/* Change the color of links on hover */
.nav a:hover {
  color: #00adf2;
}

/* Dropdown Button */
.dropbtn {
  color: white;
  /* padding: 16px; */
  /* font-size: 12px; */
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  padding-top: 1em;
  left: 50%;
  transform: translateX(-50%);
  text-align:center;
  display: none;
  position: absolute;
  background-color: white;
  color: #00adf2;
  min-width: 200px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  margin: auto;
  font-size: 16px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd; color: #00adf2}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.form {
  width: 500px;
  /* margin: 50px; */
  margin: auto;
  text-align: left;
}

.form-content {
  padding: 10px;
}
.form-error {
  text-align: left;
  padding-left: 13px;
  font-size: 12px;
  color: red;

}
.form input {
  width : 500px;
}
.form textarea {
  width: 500px;
  margin-bottom: 20px;
}

.form-header {
  text-align: center;
  font-size: 40px;
  font-weight: 200;
  letter-spacing: 7px;
}


.button {
    display: inline-block;
    background-color: #00adf2;
    color: #fff;
    font-family: "Proxima Nova",Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 0;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding-top: 22px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 5px;
}

.button:hover{
    text-decoration: none;
    color: #fff;
}

/* .button hover {
    color: body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  } */

.icon {
    width: "200px";
}

p {
    font-weight: 300;
    font-size: 18px;
}

li {
    font-weight: 300;
    font-size: 18px;
}

h1 {
    font-weight:300;
}

.footer-main {
    background-color: white;
    max-width: 1300px;
    min-height: auto;
    margin: auto;
}

.footer-link {
    text-decoration: none;
    font-size: 16px;
    color: #C0C0C0;
}
.footer-link:hover {
    text-decoration: underline;
    color: #C0C0C0;
}

.phone {
    color: black;
    text-decoration: none;
}
.phone:hover {
    color: black;
    text-decoration: underline;
}

.email {
    color: black;
    text-decoration: none;
}
.email:hover {
    color: black;
    text-decoration: underline;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {color: blue;} */
