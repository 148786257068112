.photo {
  height: 130px;
  width: auto;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 900px) {
    .photo {
        height: 75px;
        width: auto;
        padding: 0;
        margin: 0;
    }
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.button {
    display: inline-block;
    background-color: #00adf2;
    color: #fff;
    font-family: "Proxima Nova",Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 0;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding-top: 22px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.button:hover {
    color: "$fff";
}

.appt span {
    position: absolute;
    top: 12px;
    left: 17px;
    width: 17px;
    height: 17px;
    background-image: url(Icons/sprite.png);
    background-position: -122px -119px;
    background-position-x: -122px;
    background-position-y: -119px;
}


/* .p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
} */